import React  from 'react'
import {Text,Box, Heading, SimpleGrid} from '@chakra-ui/react'


import {StaticImage} from 'gatsby-plugin-image'
import MeinSEO from '../components/SEO'


export default function Shylock ({data,location}){
  
 

    return(
<>
  <MeinSEO title="Podium. Szene und Dialog. Shylock erklärt Shylock "
  description="Podium. Szene und Dialog "
  />
  <Box p="3" maxWidth={1100}>
  <Heading variant="titel" mt="8"  pt="3">Podium Szene + Dialog<br/>Shylock erklärt Shylock</Heading>

 {/* <Heading pt="-1" variant="title">Shylock erklärt Shylock</Heading> */}

  <SimpleGrid gap={3} columns={[1,1,2]}>
 <Box flex="1">
     <Box  my="1">
    
    <Box maxHeight="460px" overflow="hidden" my="1">
    
    <StaticImage src="../images/web/teaser-2.jpg" 
   
     alt="Teaser zum Dialog"
    />
     
    </Box>
    
    
   



   
  </Box>
  
  <Text my="6" pt="8" mt="2" >
  Der Kommentar Heines zu der Shakespeare-Figur Shylock bietet Sprengstoff. 
  Shylock, der bei Shakespeare fragt: 
  </Text>
  <Text my="6" variant="zitat">
  „Wenn ein Jude einen Christen beleidigt, was ist seine Demut? Rache. Und wenn ein Christ einen Juden beleidigt, was muss seine Geduld sein nach christlichem Vorbild? Nu, Rache“. Oder Heines lakonische Feststellung: „In der Weltgeschichte hat jeder Recht, sowohl der Hammer, als der Amboss“. 
  </Text>
  
 <Text my="3"> Die Szene SHYLOCK ERKLÄRT SHYLOCK aus der RABBI­Inszenierung wird dem Podium vorangestellt und gibt den Impuls für ein Gespräch mit Prof. Dr. Mirjam Wenzel, Direktorin des Jüdischen Museums Frankfurt, über Heinrich Heine und die jüdische Situation in histori­scher und aktueller Perspektive.</Text>
  <br/>
  
 

<Text variant="zitat" my="0">
„Jüdisches Leben in Europa ist vielfältig und bedroht. Es basiert auf einer jahrhundertelangen Geschichte des Ringens um Gleichberechtigung und soziale Teilhabe sowie der ebenso alten Erfahrung von Diskriminierung und Gewalt. Beides sind Themen von unverminderter Aktualität... Sie werfen die Frage auf, wie wir in einer zunehmend diversen und polarisierten Gesellschaft zusammenleben wollen und können.“ (Mirjam Wenzel)
</Text>


</Box>
<Box bg="gray.200" p="3" my="1" flex="1">
<Box  m="1"><Heading variant="title"> Podium</Heading><Text mt="2" >
   
   Prof. Dr. Mirjam Wenzel<br/>
   Willy Praml<br/>
   Michael Weber


   </Text>  </Box>
  <Box>
  <Box m="1"><Heading variant="title"> Moderation</Heading><Text mt="2" >
Werner Heinz



  </Text></Box>
  
  <Box m="1"><Heading variant="title"> Regie</Heading><Text mt="2" >
Willy Praml



  </Text></Box>
  <Box   m="1"  ><Heading variant="title"> Darsteller</Heading><Text mt="2" >
  Jacob Gail, Shylock
  </Text>
  </Box>

  </Box>
  <Box>
 
      <Box  m="1"><Heading variant="title"> Musik</Heading><Text mt="2" >Sascha Wild, Schlagzeug</Text></Box>


  <Box pb="10"  m="1"><Heading variant="title"> Bacharach</Heading><Text mt="2" >
  Sonntag, 10. Juli 2021 <br/> von 11:00 - 13:00 Uhr<br/> Josefskapelle, Bacharach



  </Text></Box>
  <Box mt="12">
  <Text  mb="1">  
  
  Gefördert von:<br/>
<span style={{fontWeight:500}}>1700 Jahre Jüdisches Leben in Deutschland</span></Text>
 <Box maxWidth="120" pt="3">
 
<StaticImage src="../images/logos/leben.jpg" />
</Box>
  </Box> 
 
  </Box>
 


  
  </Box>

</SimpleGrid>

 
</Box>

      </>
    )
    
}

